import { render, staticRenderFns } from "./ServiceLogView.vue?vue&type=template&id=0fcaa483&scoped=true"
import script from "./ServiceLogView.vue?vue&type=script&setup=true&lang=js"
export * from "./ServiceLogView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fcaa483",
  null
  
)

export default component.exports